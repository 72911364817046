import Link from 'next/link';
import { useRouter } from 'next/router';
import RatingStars from 'components/nikon-ui/atoms/RatingStars';
import { IMAGE_SOURCE } from 'helpers/constants/imageSources';
import { useFormat } from 'helpers/hooks/useFormat';
import { useLocale } from 'helpers/hooks/useLocale';
import { tabsEventKey } from 'types/ETabKeys';
import { TNikonProduct, TNikonVariant } from 'types/TNikonProduct';
import FeaturedProductPrice from './FeaturedProdcutPrice';
import styles from './FeaturedProductForSearch.module.scss';
import { FeaturedProduct } from '../../../../../../../../../../types/search/FeaturedProduct';

interface IProps {
  featuredProduct: FeaturedProduct;
}

const FeaturedProductForSearch = ({ featuredProduct }: IProps) => {
  const router = useRouter();
  const { locale, isUsaStore } = useLocale();
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const imageUnavailable = IMAGE_SOURCE.getUnavailableImage(router.locale);
  const product = featuredProduct.product;
  const masterVariant = product.variants[0] as TNikonVariant;
  const variantsQuantity = product.variants?.filter((variant) => variant.attributes?.isAvailableForSale)?.length || 0;

  if (!masterVariant) return null;

  return (
    <div className={styles[`showcase-product-hero`]}>
      <Link href={`${product?._url}/overview`}>
        <a className={styles['product-img-link']}>
          <img
            src={masterVariant?.representativeImage || masterVariant?.images?.[0] || imageUnavailable}
            onError={({ currentTarget }) => {
              currentTarget.src = imageUnavailable;
            }}
            alt={product.name}
          />
        </a>
      </Link>

      <div className={styles[`content`]}>
        <div className={styles[`main-text`]}>
          <Link href={`${product?._url}/overview`}>
            <a>
              <h3 className={styles[`primary-title`]}>{product.name}</h3>
            </a>
          </Link>

          <p className={styles[`description`]}>{product.metaDescription || product.description}</p>

          <ul className={styles['links']}>
            {featuredProduct?.linkGroups?.map(({ title, href }) => (
              <li key={title}>
                <Link href={href}>
                  <a>{title}</a>
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles[`extra-content`]}>
          {variantsQuantity > 1 && (
            <span className={styles['variants-available']}>
              {formatProductMessage({
                id: 'product.options',
                defaultMessage: '{optionsAmount} Options Available',
                values: { optionsAmount: variantsQuantity },
              })}
            </span>
          )}

          <FeaturedProductPrice variant={masterVariant} />

          {product && masterVariant && isUsaStore && (
            <div className={styles['rating-container']}>
              <Link
                href={`${product?._url}/overview#${tabsEventKey.RATING_AND_REVIEW}`}
                data-testid="brow-rating-section"
              >
                <a>
                  <RatingStars
                    product={product as TNikonProduct}
                    variant={masterVariant as TNikonVariant}
                    locale={locale}
                  />
                </a>
              </Link>
            </div>
          )}

          <button
            className={styles[`primary-cta`]}
            type="button"
            onClick={() => {
              router.push(`${product?._url}/overview`);
            }}
          >
            {formatProductMessage({ id: 'product.learnMore', defaultMessage: 'Learn More' })}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeaturedProductForSearch;
