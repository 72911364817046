import Link from 'next/link';
import { useRouter } from 'next/router';
import { Col, Row } from 'react-bootstrap';
import { IMAGE_SOURCE } from 'helpers/constants/imageSources';
import { useFormat } from 'helpers/hooks/useFormat';
import { getLocalizationInfo } from 'project.config';
import { tabsEventKey } from 'types/ETabKeys';
import { TLocalizationInfo } from 'types/TLocalizationInfo';
import styles from './ProductTilesForSearch.module.scss';
import { ISearchResults } from '../../types';
import RatingStars from '../RatingStarsForSearch';

interface IProps {
  searchItem: ISearchResults;
}
const ProductTilesForSearch = ({ searchItem }: IProps) => {
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'search' });
  const { locale } = useRouter();
  const localizationInfo: TLocalizationInfo = getLocalizationInfo(locale);
  const imageUnavailable = IMAGE_SOURCE.getUnavailableImage(locale);

  return (
    <Row className={styles['search-result-hero']}>
      <Col md={3} className={styles[`image`]}>
        <Link href={searchItem?.url}>
          <a>
            <img
              src={searchItem?.image || imageUnavailable}
              onError={({ currentTarget }) => {
                currentTarget.src = imageUnavailable;
              }}
              alt={searchItem?.title}
            />
          </a>
        </Link>
      </Col>
      <Col md={9} className={styles[`content`]}>
        <Row>
          <Col md={9} className={styles[`main-text`]}>
            <Row>
              <Col md={12}>
                <a className={styles[`url`]} href={`${searchItem?.url}`}>
                  <div dangerouslySetInnerHTML={{ __html: searchItem?.highlight?.title || searchItem.title }} />
                  {Boolean(searchItem?.url) &&
                    Boolean(searchItem.url.includes('-refurbished')) &&
                    formatCommonMessage({ id: 'search.refurbished', defaultMessage: `Refurbished` })}
                </a>
              </Col>
              <Col md={12} className={styles[`product-description`]}>
                <p
                  className={styles[`description`]}
                  dangerouslySetInnerHTML={{
                    __html: searchItem?.highlight?.description || searchItem.description,
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col md={3} className={styles[`extra-content`]}>
            {searchItem?.niProductRatingCt !== undefined && searchItem?.niProductRatingVL !== undefined && (
              <Link
                href={`${searchItem?.url}/overview#${tabsEventKey.RATING_AND_REVIEW}`}
                data-testid="brow-rating-section"
              >
                <a className={styles[`review`]}>
                  <RatingStars
                    ratingCount={parseInt(searchItem?.niProductRatingCt) || 0}
                    reviewableAt={searchItem?.niReviewable_at}
                    avgRating={parseInt(searchItem?.niProductRatingVL) || 0}
                    locale={localizationInfo.appLocale}
                  />
                </a>
              </Link>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProductTilesForSearch;
