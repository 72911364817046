import React, { useEffect, useMemo } from 'react';
import Head from 'next/head';
import { Product } from '@commercetools/frontend-domain-types/product/Product';
import ProductList, { Props as ProductListProps } from 'components/commercetools-ui/organisms/product/product-list';
import Search from 'components/commercetools-ui/organisms/product/product-list/components/search';
import SearchProvider from 'components/commercetools-ui/organisms/product/product-list/components/search/context';
import ProductListProvider, {
  useProductList,
} from 'components/commercetools-ui/organisms/product/product-list/context';
import { TableDefinition } from 'components/commercetools-ui/organisms/product/product-list/context/types';
import {
  FacetConfiguration,
  PriceConfiguration,
} from 'components/commercetools-ui/organisms/product/product-list/types';
import { CategoryPageDTO } from 'DTO/CategoryPageDTO';
import { useFormat } from 'helpers/hooks/useFormat';
import { ICategoryPage } from 'interfaces/ICategoryPage';
import { Category } from 'types/category';
import { Tastic } from 'types/tastic';
import { FilterField } from '../../../../../types/product/FilterField';
import { FeaturedCategory } from '../../../../../types/search/FeaturedCategory';
import { FeaturedProduct } from '../../../../../types/search/FeaturedProduct';
import { FIELDS } from '../../../../components/commercetools-ui/organisms/product/product-table/Header/constants';
import { useLocale } from '../../../../helpers/hooks/useLocale';
import { useAccount } from 'frontastic';
import { sendGTMEvent } from 'components/headless/GTMSnippet';
import { PAGE_VIEW_EVENTS, USER_CATEGORY } from 'helpers/constants/seoConstants';
import ErrorPage from 'components/contentstack/ErrorPage';

interface TasticProps {
  facetsConfiguration: FacetConfiguration[];
  pricesConfiguration: PriceConfiguration[];
}

export type Props = Tastic<
  ProductListProps & {
    items: Product[];
    category: string;
    facets: FilterField[];
    total: number;
    totalItems: number;
    plpConfig: {
      view: string;
      tableViewColumns: { value: string; suffix?: string; prefix?: string }[];
      tableDefinitions: TableDefinition[];
      sortOptions: { activeSortOptions: string[]; defaultSortOption: string };
    };
    content: {
      data: ICategoryPage;
    }[];
    archive: { items: Product[]; total: number; query: any };
    featuredProduct?: FeaturedProduct;
    featuredCategory?: FeaturedCategory;
    searchTerm?: string;
    slug?: string;
  },
  TasticProps
> & {
  categories: Category[];
};

const ProductListWrapped: React.FC<Props> = ({ data, categories }) => {
  const { authorized } = useLocale();
  const { updatePricesConfiguration, updateFacetsConfiguration, updateUiState } = useProductList();
  const categorySlug = data.data?.dataSource?.category?.split('/').at(-1);
  const slug = data.data?.dataSource?.slug?.split('/').at(-1);
  const { formatMessage } = useFormat({ name: 'common' });
  const { account, accountLoading } = useAccount();

  const pricesConfiguration = useMemo<Record<string, PriceConfiguration>>(() => {
    return (data.pricesConfiguration ?? []).reduce(
      (acc, configuration) => ({
        ...acc,
        [configuration.key]: {
          ranges: configuration.ranges,
        } as PriceConfiguration,
      }),
      {},
    );
  }, [data.pricesConfiguration]);

  const category = categories.find((c) => c.slug === categorySlug);

  useEffect(() => {
    if (window !== undefined && !accountLoading && category?.name && categorySlug) {
      sendGTMEvent({
        event: 'page_view',
        pageName: category?.name,
        pageCategory: categorySlug,
        pageType: PAGE_VIEW_EVENTS.PLP.TYPE,
        userId: account?.accountId,
        userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      });
    }
  }, [accountLoading, categorySlug, category?.name]);

  useEffect(() => {
    updatePricesConfiguration(pricesConfiguration);
  }, [pricesConfiguration, updatePricesConfiguration]);

  useEffect(() => {
    updateFacetsConfiguration(data.data?.dataSource?.facets || []);
  }, [data.data?.dataSource?.facets, updateFacetsConfiguration]);

  useEffect(() => {
    if (!data.data?.dataSource) return;

    const { plpConfig, archive } = data.data.dataSource;

    const sortedTabledDefinition = plpConfig?.tableDefinitions?.sort((a, b) => {
      const aIndex = FIELDS.findIndex((field) => field.attributesKeys.includes(a.key));
      const bIndex = FIELDS.findIndex((field) => field.attributesKeys.includes(b.key));
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });

    updateUiState({
      slug: categorySlug,
      totalItems: data.data.dataSource.total ?? data.data.dataSource.totalItems,
      archiveTotalItems: archive?.total || 0,
      view: plpConfig?.view,
      tableViewColumns: plpConfig?.tableViewColumns,
      tableDefinitions: sortedTabledDefinition,
      activeSortOptions: plpConfig?.sortOptions?.activeSortOptions,
      defaultSortOption: plpConfig?.sortOptions?.defaultSortOption || plpConfig?.sortOptions?.activeSortOptions?.[0],
    });
  }, [data.data?.dataSource, updateUiState]);

  const getTitleFromSlug = (slug?: string) => {
    if (!slug) return '';

    return slug
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const isValidCategory = useMemo(() => {
    if (!categorySlug) return true;
    return !!categories.find((c) => c.slug === categorySlug);
  }, [categorySlug, categories]);

  if (!data?.data || !isValidCategory) return <ErrorPage showPageLayout={false} />;

  const { items, content, archive } = data.data.dataSource;

  const { items: archiveItems } = archive || { items: [] };

  const showArchiveSection = Boolean(archiveItems?.length) || archive?.query?.query;

  const contentBlocksData = CategoryPageDTO(content?.[0]?.data);

  if (!authorized) return null;

  const formattedTitle = Boolean(contentBlocksData?.metaData?.metaTitle)
    ? contentBlocksData?.metaData?.metaTitle
    : formatMessage({
        id: 'default.meta.title',
        defaultMessage: '{title} | Nikon USA',
        values: {
          title: category?.name || getTitleFromSlug(slug) || '',
        },
      });

  return (
    <>
      <Head>
        <title>{formattedTitle}</title>
        <meta name="description" content={contentBlocksData?.metaData?.metaDescription || ''} />
        <meta name="keywords" content={contentBlocksData?.metaData?.metaKeywords || ''} />

        <meta property="og:title" content={formattedTitle} />
        <meta name="og:description" content={contentBlocksData?.metaData?.metaDescription || ''} />
        <meta property="og:image" content={contentBlocksData?.metaData?.metaImage?.url || ''} />
      </Head>
      <ProductList
        products={items}
        archiveProducts={archiveItems}
        category={category}
        content={contentBlocksData}
        showArchiveSection={showArchiveSection}
      />
    </>
  );
};

const ProductListTastic: React.FC<Props> = ({ ...props }) => {
  const { formatMessage: formatSearchMessage } = useFormat({ name: 'search' });
  const { data } = props;

  const isSearchPage = data?.data?.dataSource?.category === 'search';

  if (!data?.data) return <ErrorPage showPageLayout={false} />;

  if (isSearchPage) {
    return (
      <SearchProvider
        featuredProduct={data.data.dataSource?.featuredProduct}
        featuredCategory={data.data.dataSource?.featuredCategory}
        searchTerm={data.data.dataSource?.searchTerm}
      >
        <Head>
          <title>
            {formatSearchMessage({
              id: 'meta.title',
              defaultMessage: 'Search | Nikon USA',
            })}
          </title>
        </Head>
        <Search />
      </SearchProvider>
    );
  }

  return (
    <ProductListProvider>
      <ProductListWrapped {...props} />
    </ProductListProvider>
  );
};

export default ProductListTastic;
